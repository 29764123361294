<template>
	<div class="network-graph" :style="{ height: height, width: width }">
		<v-dialog
			:value="!!nodeSelected"
			max-width="500px"
			@click:outside="nodeSelected = null"
			v-if="nodeSelected"
		>
			<v-card>
				<v-card-title>
					<span>{{ nodeSelected[label] }}</span>
					<v-spacer />
					<v-btn @click="nodeSelected = null" icon>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-list-item
						two-line
						v-for="(value, key) in { ...nodeSelected, children: undefined }"
						:key="key"
					>
						<v-list-item-content>
							<v-list-item-title>{{ value }}</v-list-item-title>
							<v-list-item-subtitle>{{ key }}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-card-text>
			</v-card>
		</v-dialog>

		<div class="d-flex justify-space-between mb-3">
			<div class="d-flex align-center">
				<v-btn :to="{ name: 'mypage-main' }" icon>
					<v-icon large>mdi-chevron-left</v-icon>
				</v-btn>
				<v-autocomplete
					v-model="nodeSearched"
					:search-input.sync="searchText"
					:items="searchText ? searchNodes : []"
					item-value="id"
					item-text="label"
					placeholder="이름으로 검색"
					class="mx-2"
					hide-details
					hide-no-data
					dense
					outlined
					:menu-props="{ maxHeight: 200 }"
				/>

				<v-btn @click="search" color="primary">검색</v-btn>
			</div>
			<div class="d-flex align-center">
				<v-select
					v-model="selectedDepth"
					:items="depths"
					item-text="name"
					item-value="value"
					hide-details
					placeholder="뎁스를 선택하세요"
					class="mr-1"
					dense
					outlined
				/>

				<v-btn class="ml-1" @click="reset" color="primary">
					내 위치로 돌아가기
				</v-btn>
			</div>
		</div>

		<v-container style="height: 400px" v-if="loading">
			<v-row class="fill-height" align-content="center" justify="center">
				<v-col class="text-center" cols="12">
					데이터를 불러오는 중입니다...
				</v-col>
				<v-col cols="6">
					<v-progress-linear color="primary" indeterminate rounded height="6" />
				</v-col>
			</v-row>
		</v-container>
		<div
			id="svg-container"
			style="height: 100%; width: 100%"
			ref="svgContainer"
			v-else
		></div>
	</div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import * as d3 from 'd3'

import store from '@/store'

import UserService from '@/services/UserService'

export default {
	props: {
		height: {
			type: String,
			default: '100vh',
		},
		width: {
			type: String,
			default: '100%',
		},
	},
	setup() {
		const loading = ref(false)
		const graphData = ref(null)
		const root_ = ref(null)
		const svg = ref(null)
		const height_ = ref(0)
		const width_ = ref(0)
		const zoom = ref(null)
		const label = ref('name')
		const searchText = ref('')
		const nodes = ref([])
		const searchNodes = ref([])
		const nodeSearched = ref('')
		const nodeSelected = ref(null)
		const root = ref(null)
		const selectedDepth = ref(3)
		const svgContainer = ref(null)
		const profile = computed(() => store.getters['user/getMeDetail'])
		const depths = ref([
			{
				name: '1단계',
				value: 1,
			},
			{
				name: '2단계',
				value: 2,
			},
			{
				name: '3단계',
				value: 3,
			},
		])

		const fetchGraphData = async () => {
			try {
				loading.value = true

				graphData.value = await UserService.getUserRecommenderTreeMe(
					selectedDepth.value,
				)
			} catch (e) {
				console.error(e)
			} finally {
				loading.value = false
			}
		}

		const generateNodes = () => {
			graphData.value.forEach(v => {
				if (v.parent && !nodes.value.find(node => node.id == v.parent.id)) {
					nodes.value.push(v.parent)
					searchNodes.value.push({
						id: v.parent.id,
						label: `${v.parent.name}(${v.parent.uid})`,
					})
				}
				if (v.child && !nodes.value.find(node => node.id == v.child.id)) {
					nodes.value.push(v.child)
					searchNodes.value.push({
						id: v.parent.id,
						label: `${v.parent.name}(${v.parent.uid})`,
					})
				}
			})
		}
		const getNodeBorderColor = node => {
			// Search suggestion node
			if (
				searchText.value &&
				(node?.data[label.value] || '').search(searchText.value) > -1
			) {
				return '#66cc00'
			}
			// Root node
			if (node?.data.id == root_.value) {
				return '#F44336'
			}
			// Parent node
			if ((node?.data.children || []).length) {
				return '#3395FF'
			}
			// Default
			return '#ccc'
		}

		const getNodeLabelColor = node => {
			// Search suggestion node
			if (
				searchText.value &&
				(node?.data[label.value] || '').search(searchText.value) > -1
			) {
				return '#66cc00'
			}
			// Default
			return '#000'
		}

		watch(
			() => searchText.value,
			() => {
				d3.selectAll('g')
					.select('circle')
					.attr('stroke', d => {
						return getNodeBorderColor(d)
					})
				d3.selectAll('g')
					.select('text')
					.attr('fill', d => {
						return getNodeLabelColor(d)
					})
			},
		)

		watch(
			() => label.value,
			currentValue => {
				if (currentValue) {
					d3.selectAll('g')
						.select('text')
						.text(d => d?.data[label.value])
				}
			},
		)

		const generateTree = () => {
			let tree = []
			const dict = {}

			graphData.value.forEach(v => {
				dict[v.parent.id] = {
					...(dict[v.parent.id] || { ...v.parent }),
					children: [],
				}
				dict[v.child.id] = {
					...(dict[v.child.id] || { ...v.child }),
					parentId: v.parent.id,
					children: [],
				}
			})

			for (const key in dict) {
				const user = dict[key]

				if (root_.value && root_.value == user.id) {
					tree.push(user)
					continue
				}

				if (user.parentId && dict[user.parentId]) {
					dict[user.parentId].children.push(user)
				} else {
					if (!root_.value) {
						tree.push(user)
					}
				}
			}

			// One root
			if (tree.length == 1) {
				tree = tree[0]
			}
			// Multiple roots
			else {
				tree = {
					id: 0,
					name: 'SYSTEM',
					children: tree,
				}
			}

			return tree
		}

		// Center view to root node
		const resetView = () => {
			const rootNode = d3.select(`#node-${root.value}`).data()[0]
			if (rootNode) {
				const scale = 1
				svg.value
					.transition()
					.duration(0)
					.call(
						zoom.value.transform,
						d3.zoomIdentity
							.scale(scale)
							.translate(
								width_.value / 2 - scale * rootNode.x,
								height_.value / 2 - scale * rootNode.y,
							),
					)
			}
		}

		const initGraph = async () => {
			// Fetch graph data
			try {
				svg.value?.remove()

				height_.value = svgContainer.value.offsetHeight
				width_.value = svgContainer.value.offsetWidth

				svg.value = d3
					.select('#svg-container')
					.append('svg')
					.attr('height', height_.value)
					.attr('width', width_.value)

				const tree = generateTree()

				const root = d3.hierarchy(tree)
				const links = root.links()
				const nodes = root.descendants()

				// Create force simulation
				const simulation = d3
					.forceSimulation(nodes)
					.force('link', d3.forceLink(links))
					.force('charge', d3.forceManyBody().strength(-3000))
					.force('center', d3.forceCenter(width_.value / 2, height_.value / 2))
					.force('x', d3.forceX(width_.value / 2))
					.force('y', d3.forceY(height_.value / 2))
				// .on('tick', () => {
				// 	link
				// 		.attr('x1', d => d.source.x)
				// 		.attr('y1', d => d.source.y)
				// 		.attr('x2', d => d.target.x)
				// 		.attr('y2', d => d.target.y)
				// 	node.attr('transform', d => `translate(${d.x}, ${d.y})`)
				// })

				// Main container of shapes (e.g. lines, circles, etc)
				// Element to be transformed in the zoom event
				const g = svg.value.append('g').attr('id', 'svg-shapes')

				// Links (Lines)
				// const link = g
				// 	.append('g')
				// 	.attr('stroke', '#33CEFF')
				// 	.attr('stroke-opacity', 0.3)
				// 	.attr('stroke-width', 0)
				// 	.call(node => {
				// 		// Transition stroke width
				// 		node.attr('stroke-width', 1).transition().duration(0)
				// 	})
				// 	.selectAll('line')
				// 	.data(links)
				// 	.join('line')

				// Nodes (Container)
				const node = g
					.append('g')
					.selectAll('g')
					.data(nodes)
					.enter()
					.append('g')
					.attr('id', d => `node-${d?.data.id}`)

				// Nodes (Circles)
				node
					.append('circle')
					.attr('stroke', d => {
						return getNodeBorderColor(d)
					})
					.attr('stroke-width', d => {
						// Root node, Parent node
						if (d?.data.id == root_.value || (d?.data.children || []).length) {
							return 2
						}
						// Default
						return 1
					})
					.attr('fill', d => `url(#image-${d.index})`)
					.attr('cursor', 'pointer')
					.attr('r', 0)
					.call(node => {
						// Transition radius
						node.transition().duration(0).attr('r', 10)
					})
					.selectAll('circle')
					.data(nodes)

				// Nodes (Image)
				node
					.append('defs')
					.append('pattern')
					.attr('id', d => `image-${d.index}`)
					.attr('width', 1)
					.attr('height', 1)
					.append('image')
					.attr('xlink:href', require('@/assets/images/avatars/default.svg'))
					.attr('x', 0)
					.attr('y', 0)
					.attr('width', 0)
					.attr('height', 0)
					.call(node => {
						// Transition image size
						node.attr('width', 20).attr('height', 20).transition().duration(0)
					})

				// Nodes (Labels)
				node
					.append('text')
					// .text(d => d.data[label.value])
					.html(function (d) {
						return (
							"<tspan x='0' dy='0em'>" +
							d.data[label.value] +
							'</tspan>' +
							"<tspan class='uid-text' x='0' dy='1em'>" +
							d.data.uid +
							'</tspan>'
						)
					})
					.attr('y', 30)
					.attr('text-anchor', 'middle')
					.attr('font-size', 15)
					.attr('font-family', 'sans-serif')
					.attr('font-weight', 'bold')
					.attr('cursor', 'pointer')
					.attr('opacity', 0)
					.call(node => {
						// Transition opacity
						node.attr('opacity', 1).transition().duration(0)
					})

				// Nodes (Children Count Mark)
				node
					.append('text')
					.text(d =>
						d?.data.id == root_.value
							? (d.data.children || []).length + 1 + ' / ' + nodes.length
							: '',
					)
					.style('fill', 'red')
					.attr('x', 15)
					.attr('y', 10)
					.attr('text-anchor', 'left')
					.attr('font-size', 12)
					.attr('font-family', 'sans-serif')
					.attr('font-weight', 'bold')
					.attr('opacity', 0)
					.call(node => {
						// Transition opacity
						node.attr('opacity', 1).transition().duration(0)
					})

				// Nodes Event Handlers
				// Simulate double click
				// @click and @dblclick doesn't work together, @click is always being triggered
				// @dblclick doesn't work on mobile
				let clickTimeout = null
				let clickCount = 0
				node.on('click', (e, d) => {
					if (e.defaultPrevented) return
					clickCount++
					clickTimeout = setTimeout(() => {
						// Single click
						if (clickCount == 1) {
							nodeSelected.value = d.data
						}
						// Double click
						else {
							// Re-draw using clicked node as root
							root_.value = d.data.id
						}
						// Reset
						clickCount = 0
						clearTimeout(clickTimeout)
					}, 300)
				})

				// Nodes Drag Handler
				node.call(
					d3
						.drag()
						.on('start', (event, d) => {
							if (!event.active) simulation.alphaTarget(0.1).restart()
							d.fx = d.x
							d.fy = d.y
						})
						.on('drag', (event, d) => {
							d.fx = event.x
							d.fy = event.y
						})
						.on('end', (event, d) => {
							if (!event.active) simulation.alphaTarget(0)
							d.fx = null
							d.fy = null
						}),
				)

				// Zoom Handler
				zoom.value = d3
					.zoom()
					.scaleExtent([0.1, 10])
					.on('zoom', e => {
						g.attr('transform', e.transform)
					})
				svg.value.call(zoom.value)

				d3.timeout(function () {
					// See https://github.com/d3/d3-force/blob/master/README.md#simulation_tick
					for (
						let i = 0,
							n = Math.ceil(
								Math.log(simulation.alphaMin()) /
									Math.log(1 - simulation.alphaDecay()),
							);
						i < n;
						++i
					) {
						simulation.tick()
					}

					g.append('g')
						.data(links)
						.attr('x1', function (d) {
							return d.source.x
						})
						.attr('y1', function (d) {
							return d.source.y
						})
						.attr('x2', function (d) {
							return d.target.x
						})
						.attr('y2', function (d) {
							return d.target.y
						})

					g.append('g')
						.data(nodes)
						.attr('cx', function (d) {
							return d.x
						})
						.attr('cy', function (d) {
							return d.y
						})

					setTimeout(() => {
						resetView()
						const container = document.getElementById('svg-container')
						container.style.visibility = 'visible'
					}, 0)
				})
			} catch (e) {
				console.log(e)
			}
		}

		const reset = () => {
			// Reset view only if it's already in default root node
			// Else reset root to default root node
			if (root_.value == root.value) {
				resetView()
			} else {
				root_.value = root.value
			}
			searchText.value = ''
			nodeSearched.value = ''
		}

		const search = () => {
			if (nodeSearched.value) {
				root_.value = nodeSearched.value
			}
		}

		watch(
			() => profile.value,
			currentValue => {
				if (currentValue) {
					root.value = currentValue.id
					resetView()
				}
			},
			{
				immediate: true,
			},
		)

		watch(
			() => selectedDepth.value,
			async currentValue => {
				if (currentValue) {
					try {
						loading.value = true

						await fetchGraphData()
						generateNodes()

						root_.value = root.value
						initGraph(root_.value)
					} catch (e) {
						console.log(e)
					} finally {
						loading.value = false
					}
				}
			},
			{
				immediate: true,
			},
		)

		return {
			svg,
			height_,
			width_,
			zoom,
			label,
			nodeSelected,
			svgContainer,
			profile,
			depths,
			selectedDepth,
			nodeSearched,
			searchText,
			nodes,
			searchNodes,
			loading,

			reset,
			resetView,
			search,
		}
	},
}
</script>

<style lang="scss">
#svg-container {
	border: 1px solid #eee;
	overflow: hidden;
	visibility: hidden;

	.uid-text {
		font-size: 11px;
	}
}
</style>
